import Home from 'components/index';
import Layout from 'components/layout';
import PropTypes from 'prop-types';
import React from 'react';

const index = () => {
  return (
    <Layout>
      <Home />
    </Layout>
  );
};

index.propTypes = {
  location: PropTypes.object
};

export default index;
