import {isEmpty} from 'lodash';
import {paths} from 'config';

const {apiPath} = paths;

/**
 * Request builder.
 * @param {string} reqPath request path
 * @param {object} query querystring object
 * @param {string} method http method
 * @param {object} payload payload object
 * @returns {object} request object
 */
export async function rb(reqPath, query = {}, method = 'GET', payload = null) {
  let qs = '';
  if (method === 'GET') {
    qs = qb(query);
  }
  const rp = buildReqPath(reqPath, qs);
  const request = {
    path: rp,
    options: {
      method
    }
  };
  if (!isEmpty(payload)) {
    request.options.body = JSON.stringify(payload);
  }
  return request;
}

/**
 * Query builder.
 * @param {object} query querystring object
 * @returns {string} querystring
 */
export function qb(query) {
  if (isEmpty(query)) {
    return '';
  }
  let qs = '?';
  for (const key in query) {
    if (query[key]) {
      qs += qs === '?' ? `${key}=${query[key]}` : `&${key}=${query[key]}`;
    }
  }
  return qs;
}

/**
 * Request path builder.
 * @param {string} reqPath request path
 * @param {string} qs querystring
 * @returns {string} request path
 */
export function buildReqPath(reqPath, qs) {
  return `${apiPath}/${reqPath}${qs}`;
}
