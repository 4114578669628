'use strict';

const paths = require('config/paths');
const {env, isDev, isStage, isProd, isTest} = require('config/envs');

const config = {
  env,
  isDev,
  isStage,
  isProd,
  isTest,

  paths
};

module.exports = config;
