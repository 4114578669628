const {isDev, isStage, isProd} = require('config/envs');

const apiPath = () => {
  if (isDev()) {
    return 'http://localhost:8080';
  } else if (isStage()) {
    return '';
  } else if (isProd()) {
    return 'https://api.simc.io';
  }
  return null;
};

const domainPath = () => {
  if (isDev()) {
    return null;
  } else if (isStage()) {
    return '';
  } else if (isProd()) {
    return '';
  }
  return null;
};

module.exports = {
  apiPath: apiPath(),
  domainPath: domainPath(),
  newsletterPath: 'newsletter'
};
