import {addEmail} from 'middleware/newsletter';
import Footer from './footer';
import Header from './header';
import React, {useState} from 'react';
import validator from 'validator';

import './index.scss';

function Home() {
  const [email, setEmail] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const submitEmail = async () => {
    if (!email || !validator.isEmail(email)) {
      setError('Please enter a valid email address');
      return;
    }

    const {error} = await addEmail(email);
    if (error) {
      setError('My sincerest apologies! An error occurred. Please try again later.');
      return;
    }
    setSubmitted(true);
  };

  const onChange = (e) => setEmail(e.target.value);
  const onKeyPress = (e) => {
    if (e.which === 13) {
      submitEmail();
    }
  };

  return (
    <>
      <Header />
      <div className="Home">
        <div className="Information">
          <div className="TitleOne">Enabling social change</div>
          <div className="TitleTwo">through media</div>
          <div className="description">
            Initially conceived as a meeting place and discussion group for films, SIMC is meant for use in a wide range
            of media forms including, but not limited to, film, television, art, books, and music. The goal of SIMC is
            to promote discussion and action through accessible topics and companies for change.
          </div>
        </div>
        <div className="SignUp">
          Sign up for our newsletter
          {!submitted ? (
            <>
              <input
                type="text"
                name="email"
                className="EmailForm"
                id="email"
                required
                autoComplete="off"
                placeholder="Enter your email"
                onChange={onChange}
                onKeyPress={onKeyPress}
              />
              {error && <p className="Error">{error}</p>}
              <p className="FormLabel">I want to receive newsletters and info about the progress of SIMC!</p>
              <button className="SubmitButton" onClick={submitEmail}>
                Submit
              </button>
            </>
          ) : (
            <p className="FormLabel red">
              Thanks for signing up to be on our newsletter! More information will arrive in your inbox shortly!
            </p>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Home;
