'use strict';

const env = process.env.GATSBY_API_ENV || 'development';
const isDev = () => env === 'development';
const isProd = () => env === 'production';
const isStage = () => env === 'staging';
const isTest = () => env === 'test';

module.exports = {
  env,
  isDev,
  isDevOrTest: () => isDev() || isTest(),
  isProd,
  isStage,
  isTest
};
